/* markdown.css */

.markdown-content {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  font-weight: bold;
  margin: 1em 0 0.5em;
}

.markdown-content p {
  margin: 1em 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.markdown-content code {
  font-family: monospace;
  background-color: rgba(27, 31, 35, 0.05);
  padding: 0.2em 0.4em;
  border-radius: 4px;
  display: inline-block;
}

.markdown-content pre {
  background-color: rgba(27, 31, 35, 0.05);
  padding: 1em;
  border-radius: 4px;
  overflow-x: auto;
}

.markdown-content blockquote {
  border-left: 4px solid #ddd;
  margin: 1em 0;
  padding-left: 1em;
  color: #666;
}

.markdown-content ul,
.markdown-content ol {
  margin: 1em 0;
  padding-left: 2em;
}

.markdown-content a {
  color: #0366d6;
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}
