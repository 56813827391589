/* General container */
.login-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
  padding: 0 20px; /* Optional: To ensure it doesn't hit the edges */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

/* Card styling with frosted glass effect */
.login-card {
  background-color: rgba(
    255,
    255,
    255,
    0.25
  ); /* More transparency for frosted glass effect */
  backdrop-filter: blur(20px); /* Stronger blur for the frosted glass effect */
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); /* Softer shadow */
  max-width: 400px;
  width: 100%;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border */
  transition:
    transform 0.5s ease,
    box-shadow 0.5s ease;
}

.login-card:hover {
  transform: translateY(-5px); /* Subtle hover effect */
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25); /* Enhanced shadow on hover */
}

.login-card h2 {
  margin-bottom: 1.5rem;
  color: rgb(55, 45, 45);
  font-size: 28px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1.2px;
}

/* Input field styling */
.login-card input {
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Slightly transparent border */
  border-radius: 8px;
  font-size: 16px;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow */
}

.login-card input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Glow effect on focus */
  outline: none;
}

.login-card input::placeholder {
  color: #888;
  font-size: 14px;
}

/* Login button */
.login-btn {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  margin-top: 1rem;
  font-family: "Roboto", sans-serif;
}

.login-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* OAuth login section */
.oauth-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width */
  margin-top: 2rem;
}

.google-login-button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 12px 20px;
  background-color: rgba(
    255,
    255,
    255,
    0.25
  ); /* Transparent background for frosted effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border to match the card */
  border-radius: 8px;
  color: #333; /* Dark text to ensure readability */
  font-size: 16px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: "Roboto", sans-serif;
  align-items: center; /* Center the text and icon vertically */
}

.oauth-login h3 {
  font-size: 16px;
  margin-bottom: 1rem;
}

/* OAuth buttons */
.oauth-btn {
  padding: 12px 20px;
  margin: 0.5rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.oauth-btn.google {
  background-color: white;
  color: #757575;
  border: 1px solid #dcdcdc;
}

.oauth-btn.google:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.oauth-btn.google img {
  margin-right: 10px;
}

.oauth-btn.facebook {
  background-color: #3b5998;
  color: white;
}

.oauth-btn.facebook:hover {
  opacity: 0.8;
}

.google-icon {
  width: 18px;
  height: 18px;
}
.error-message {
  color: #ff4d4f;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
}

/* Add this under the .login-card section for email login */
.email-login {
  margin-bottom: 2rem;
}

.email-login input {
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.3); /* Slightly transparent border */
  border-radius: 8px;
  font-size: 16px;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow */
}

.email-login input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* Glow effect on focus */
  outline: none;
}

/* Button styles */
.email-login .login-btn {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  margin-top: 1rem;
  font-family: "Roboto", sans-serif;
}

.email-login .login-btn:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Loading card specific styles */
.loading-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.loading-spinner {
  animation: spin 1.5s linear infinite;
  color: #007bff;
  width: 48px;
  height: 48px;
  margin-bottom: 1.5rem;
}

.loading-text {
  margin: 1rem 0 0.5rem 0;
  color: #333;
  font-size: 24px;
}

.loading-subtext {
  color: #666;
  font-size: 16px;
  margin: 0;
}

/* Button loading state */
.button-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.button-spinner {
  animation: spin 1s linear infinite;
  width: 18px;
  height: 18px;
}

/* Disabled button styles */
.login-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

/* Spinner animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Enhanced frosted glass effect for loading card */
.loading-card {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.4);
}

/* Smooth transitions */
.login-card {
  transition: all 0.3s ease;
}
