.document-selector {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  border-radius: 12px;
}

.section-title {
  color: #4b5563;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
}

.document-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.document-card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.document-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.document-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: currentColor;
  opacity: 0.5;
}

.document-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  cursor: pointer;
  accent-color: #4f46e5;
}

.document-name {
  flex: 1;
  font-size: 14px;
  color: #1f2937;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: auto;
}

.processing {
  color: #3b82f6;
}

.processing .status-indicator {
  background: #3b82f6;
  animation: pulse 2s infinite;
}

.completed {
  color: #10b981;
}

.completed .status-indicator {
  background: #10b981;
}

.error {
  color: #ef4444;
}

.error .status-indicator {
  background: #ef4444;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Custom checkbox styles */
.document-checkbox {
  appearance: none;
  background-color: #fff;
  border: 2px solid #d1d5db;
  border-radius: 4px;
  transition: all 0.2s;
}

.document-checkbox:checked {
  background-color: #4f46e5;
  border-color: #4f46e5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='white'%3E%3Cpath d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'/%3E%3C/svg%3E");
}

.document-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #4f46e5;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .document-selector {
    padding: 16px;
  }

  .document-card {
    padding: 12px;
  }
}

.confirm-button {
  background-color: #3b4c9b;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 8px auto 16px auto;
  font-size: 14px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 80%;
  display: block;
}

.confirm-button:hover:not(.disabled) {
  background-color: #324083;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.confirm-button.disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  box-shadow: none;
}

.confirm-button.disabled:hover {
  background-color: #9ca3af;
  box-shadow: none;
}
