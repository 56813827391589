/* Overall styling for the file uploader */
.file-uploader {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  height: 100%;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0e0e0;
  transition: transform 0.3s ease;
  overflow: hidden; /* Prevents clipping of content */
}

.file-uploader-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.file-uploader-header h2 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333;
}

/* Main content area of the file uploader */
.file-uploader-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Make content scrollable */
  width: 100%; /* Ensures the content spans the full width */
}

/* Drag and drop area styling */
.drag-drop-area {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  margin-top: 10px;
  width: 91%;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  transition: border-color 0.3s ease;
}

.drag-drop-area:hover {
  border-color: #3b4c9b;
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4a5568;
}

.file-placeholder p {
  margin: 5px 0;
  font-size: 14px;
}

.file-icon {
  font-size: 40px; /* Reduced icon size for more space */
  color: #3b4c9b;
  margin-bottom: 8px; /* Reduced margin for better spacing */
}

.browse-button,
.upload-button {
  background-color: #3b4c9b;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 8px;
  font-size: 14px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 80%;
}

.browse-button:hover,
.upload-button:hover {
  background-color: #324083;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.upload-button:disabled {
  background-color: #afadad;
  cursor: not-allowed;
}

/* File preview list styling */
.file-preview-list {
  flex-grow: 1;
  width: 95%;
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
  padding-right: 10px;
  transition: max-height 0.3s ease;
}

.file-preview-list.single-file {
  max-height: 60px;
}

/* Individual file preview item styling */
.file-preview-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 95%; /* Center align and control width */
  margin: 0 auto 8px auto;
}

.file-preview-item:hover {
  background-color: #e8f5fe; /* Light blue background on hover */
  transform: translateY(-2px);
}

.file-preview-item .file-name {
  font-weight: bold;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%; /* Adjusted width to allow more text display */
  cursor: pointer;
}

.file-preview-item .file-name:hover {
  text-decoration: underline;
}

.remove-file-btn {
  background: none;
  border: none;
  color: #888;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
  transition: color 0.3s ease;
}

.remove-file-btn:hover {
  color: #ff4c4c;
}

.file-preview-container {
  width: 96%;
  height: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 10px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.tab-list {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.tab-item {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px 10px;
  margin-right: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  font-size: 10px;
  white-space: nowrap;
}

.tab-item.active {
  background-color: #3b4c9b;
  color: white;
  border-color: #3b4c9b;
}

.tab-item:hover {
  background-color: #324083;
  color: white;
}

.remove-file-tab {
  background: none;
  border: none;
  color: #888;
  font-size: 14px;
  margin-left: 6px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.remove-file-tab:hover {
  color: #ff4c4c;
}
